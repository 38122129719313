import MaxWidth from '../MaxWidth/MaxWidth'
import styles from './Header.module.css'
import logo from '../../assets/images/logo.svg'
import { useEffect, useState } from 'react'
import Burger from './Burger/Burger'
import useOnScreen from '../../hooks/useOnScreen'



const Header = ({refs,contactRef}) => {
    const [activeIndex, setActiveIndex] = useState(0)
    const headerElements = [
        {
            text: "Home",
            element_id: "home"
        },
        {
            text: "News",
            element_id: "news"
        },
        {
            text: "Play",
            element_id: "play"
        },
        {
            text: "About us",
            element_id: "about_us"
        },
        {
            text: "Contact Us",
            element_id: "contact_us"
        },
    ]
    const homeRef = useOnScreen(refs.homeRef)
    const playRef = useOnScreen(refs.playRef)
    const aboutRef = useOnScreen(refs.aboutRef)
    const newsRef = useOnScreen(refs.newsRef)
    const contactRefs = useOnScreen(contactRef)
    useEffect(() => {
        if(homeRef){
            setActiveIndex(0)
        }
        if(newsRef){
            setActiveIndex(1)
        }
        if(playRef){
            setActiveIndex(2)
        }
        if(aboutRef){
            setActiveIndex(3)
        }
        if(contactRefs){
            setActiveIndex(4)
        }
    }, [homeRef,playRef,aboutRef,contactRefs,newsRef])
    const scrollToElement = (i,elementId) => {
        setActiveIndex(i)
        const element = document.getElementById(elementId);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    return (
        <div className={styles.header}>  
            <MaxWidth>
                <div className={styles.headerFlex}>
                    <div className={styles.logoPart}>
                        <img src={logo} alt="" />
                        <span>HAYQ Studios</span>
                    </div>
                    <div className={styles.menuPart}>
                        {headerElements.map((el, i) => (
                            <div key={i} className={styles.headerTextDiv} onClick={() => scrollToElement(i, el.element_id)}>
                                <a className={`${styles.headerText} ${activeIndex == i || activeIndex == el.text && styles.activeBlock}`}>{el.text}</a>
                                {activeIndex == i && 
                                    <div className={styles.underline}></div>
                                }
                            </div>
                        ))}
                    </div>
                    <div className={styles.burger}>
                        <Burger 
                            links={headerElements}
                            activeIndex={activeIndex}
                            setActiveIndex={setActiveIndex}
                        />
                    </div>
                </div>
            </MaxWidth>
        </div>
    )
}

export default Header