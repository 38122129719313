import { useContext, useState } from 'react'
import styles from './Burger.module.css'
import Drawer from '../../Drawer/Drawer'
import { Context } from '../../../index'

const Burger = ({ 
    links,
    activeIndex,
    setActiveIndex
}) => {
    const [showMenu, setShowMenu] = useState(false)
    const changeActiveIndex = (index) => setActiveIndex(index)
    const openBurger = () => setShowMenu(prev => !prev)
    const scrollToElement = (i,elementId) => {
        setActiveIndex(i)
        const element = document.getElementById(elementId);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
    }
    return (
        <div className={styles.burger}>
            <div className={styles.burgerFlexDiv}>
                <div className={styles.burgerMenu} onClick={openBurger}>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
            <Drawer className={`${styles.comingMenu} ${showMenu ? styles.activeMenuFalse : styles.activeMenu}`} showDrawer={showMenu}>
                <div className={styles.topPart}>
                    <div className={styles.closeIcon} onClick={openBurger}>
                        <div></div>
                        <div></div>
                    </div>
                    <div className={styles.buttonsFlex}>
                        {/* <Button text={"Sign Up"} buttonStyle="standart" className={styles.signUpButton} onClick={() => store.setRegisterPopup(true)} /> */}
                        {/* <Button text={"Log In"} className={styles.loginButton} onClick={() => store.setLoginPopup(true)} /> */}
                    </div>
                </div>
                <div className={styles.pageLinks}>
                    {/* {links.map((el, i) => (
                        <PageLink className={styles.pageLink} key={i} index={i == activeIndex} text={el.text} link={el.link} onClick={() => changeActiveIndex(i)} />
                    ))} */}
                    {links.map((el, i) => (
                        <div key={i} className={styles.headerTextDiv} onClick={() => scrollToElement(i, el.element_id)}>
                            <a className={`${styles.headerText} ${activeIndex == i && styles.activeBlock}`}>{el.text}</a>
                            {activeIndex == i && 
                                <div className={styles.underline}></div>
                            }
                        </div>
                    ))}
                </div>
            </Drawer>
        </div>
    )
}

export default Burger