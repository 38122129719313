import { useRef } from 'react';
import styles from './App.css'
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import Main from "./components/Main/Main";


function App() {
  const homeRef = useRef(null)
  const playRef = useRef(null)
  const aboutRef = useRef(null)
  const contactRef = useRef(null)
  const newsRef = useRef(null)
  const refs = {homeRef,playRef,aboutRef,newsRef}

  return (
    <div>
      <Header 
      refs={refs}
      contactRef={contactRef}
      />
      <Main 
        refs={refs}
      />
      <Footer
        contactRef={contactRef}
      />
    </div>
  );
}

export default App;
