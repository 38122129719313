import styles from './MaxWidth.module.css'


const MaxWidth = ({children}) => {
    return (
        <div className={styles.maxWidth}>
            {children}
        </div>
    )
}


export default MaxWidth