import styles from './Drawer.module.css'


const Drawer = ({children, className, showDrawer}) => {
    return (
        <div className={`${className} ${showDrawer ? styles.activeMenuFalse : styles.activeMenu}`}>
            {children}
        </div>
    )
}

export default Drawer