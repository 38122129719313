import styles from './Main.module.css'
import topPart from '../../assets/images/banner.jpg'
import longVid from '../../assets/videos/long.mp4'
import ashley from '../../assets/images/ashley.png'
import britney from '../../assets/images/britney.png'
import mia from '../../assets/images/mia.jpg'
import jessica from '../../assets/images/jessica.png'
import slide1 from '../../assets/images/slide1.png'
import slide2 from '../../assets/images/slide2.png'
import news1 from '../../assets/images/news1.jpg'
import news2 from '../../assets/images/news2.jpg'
import MaxWidth from '../MaxWidth/MaxWidth'
import company3 from '../../assets/images/company3.svg'
import company4 from '../../assets/images/company4.svg'
import company5 from '../../assets/images/company5.svg'
import girl1 from '../../assets/images/girl1.png'
import girl2 from '../../assets/images/girl2.png'
import girl3 from '../../assets/images/girl3.png'
import girl4 from '../../assets/images/girl4.png'
import girl5 from '../../assets/images/girl5.png'
import girl6 from '../../assets/images/girl6.png'
import club11 from '../../assets/images/11 CLUB.svg'
import zen from '../../assets/images/ZEN.svg'
import sharm from '../../assets/images/SHARM.svg'
import eliteGlobe from '../../assets/images/eliteGlobe.svg'
import { Swiper, SwiperSlide } from 'swiper/react';
import Marquee from "react-fast-marquee";
import 'swiper/css';
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import './Slide.css'
import SwiperCore from "swiper/core";
import { useEffect, useState } from 'react'
import { Autoplay, Parallax, EffectCoverflow, Navigation, Pagination } from 'swiper'

SwiperCore.use([EffectCoverflow, Navigation, Pagination, Autoplay, Parallax])

const Main = ({ refs }) => {
    const [brokeSlide, setBrokeSlide] = useState({
        isBeginning: true,
        isEnd: false
    })
    const [activeIndex, setActiveIndex] = useState(0)
    const slidesImages = [
        {
            image: mia,
            name: "Mia"
        },
        {
            image: ashley,
            name: "Ashley"
        },
        {
            image: jessica,
            name: "Jessica"
        },
        {
            image: britney,
            name: "Britney"
        },

    ]
    const companies = [
        // {
        //     image: company3,
        //     link: ""
        // },
        {
            image: company5,
            link: "https://store.steampowered.com/app/2422910/Love_Quest_VR_Los_Angeles/"
        },
        // {
        //     image: company4,
        //     link: ""
        // },
    ]
    const slides2 = [
        slide1,
        slide2,
        slide1,
        slide2,
    ]
    const slidesGirls = [
        girl1,
        girl2,
        girl3,
        girl4,
        girl5,
        girl6,
    ]
    const slides3 = [
        eliteGlobe,
        zen,
        club11,
        sharm
    ]
    let settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        beforeChange: (current, next) => setActiveIndex(next)
    }



    return (
        <div className={styles.main}>
            <div className={styles.topPart} id="home" ref={refs.homeRef}>
                <div className={styles.shadow1}></div>
                <img src={topPart} alt="" className={styles.topPartImage} />
                <div className={styles.shadow2}></div>
            </div>
            <div className={styles.gameIdea}>
                <MaxWidth>
                    <div className={styles.gameIdeaTop}>
                        <div className={styles.gameIdeaBgDiv}></div>
                        <span className={styles.gameIdeaText}>Game Idea</span>
                    </div>
                    <div className={styles.gameIdeaInfo}>
                        <span className={styles.gameIdeaInfoText}>Love Quest VR: Los Angeles is a training simulator romantic game that is designed to provide men with the skills necessary for successful interactions with women of various personalities and styles in Los Angeles. In this modern world, where many are primarily engaged in virtual activities, a lot of guys struggle to interact effectively with members of the opposite sex or comprehend what makes them tick - but that won't be an issue anymore! This engaging experience will equip males everywhere to confidently pick up girls, approach these conversations and reach their desired destination.</span>
                    </div>

                    <div className={styles.companies}>
                        <div className={styles.companiesFlex}>
                            {companies.map((el, i) => (
                                <div key={i} className={styles.companieImageWrapper}>
                                    <img src={el.image} className={styles.companyImage} />
                                    <a href={el.link} target='_blank' className={`${styles.gameIdeaButton} ${styles.sizeChanged}`}>
                                        <span>Play</span>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </MaxWidth>
            </div>
            <div className={styles.mainCharters}>
                <MaxWidth>
                    <div className={styles.chartersTop}>
                        <div className={styles.gameIdeaTop}>
                            <div className={styles.gameIdeaBgDiv}></div>
                            <span className={styles.gameIdeaText}>Main Charters</span>
                        </div>
                        <div className={styles.chartersSlides}>
                            {slidesImages.map((el, i) => (
                                <div className={styles.childSlide} key={i}>
                                    <img src={el.image} alt="" />
                                </div>
                            ))}
                        </div>
                    </div>
                </MaxWidth>
            </div>
            <div className={styles.bottomPartImage}>
                <div className={styles.topPart}>
                    <div className={styles.shadow1}></div>
                    {/* <img src={bottomPart} alt="" className={styles.topPartImage} /> */}
                    <video controls muted autoPlay id='longVid' src={longVid} className={styles.topPartImage} type="video/mp4" />
                </div>
            </div>
            <div className={styles.gameplay} id='news' ref={refs.newsRef}>
                <MaxWidth>
                    <div className={styles.gameIdeaTop}>
                        <div className={styles.gameIdeaBgDiv}></div>
                        <span className={styles.gameIdeaText}>News</span>
                    </div>
                    <div className={styles.newsFlex}>
                        <div className={styles.news}>
                            <img src={news1} alt="" />
                            <a href='https://hayqgames.com/news' target='_blank'>Our Gaming Industry</a>
                        </div>
                        <div className={styles.news}> 
                            <img src={news2} alt="" />
                            <a href='https://hayqgames.com/news' target='_blank'>Games Industry Events in 2022</a>
                        </div>
                    </div>
                </MaxWidth>
            </div>
            <div className={styles.gameplay}>
                <MaxWidth>
                    <div className={styles.gameIdeaTop}>
                        <div className={styles.gameIdeaBgDiv}></div>
                        <span className={styles.gameIdeaText}>Gameplay</span>
                    </div>
                    <div className={styles.gameIdeaInfo}>
                        <span className={styles.gameIdeaInfoText}>On his quest to win over the girl of his dreams, a man crafted an intricate plan. He approached her and asked 20-25 cleverly selected questions during their first date at one place, followed by another set of 20-25 carefully chosen queries in a different setting for their second date. Each scene has 4 question options: 1 good, 2 bad and 1 neutral. A man only should ask good questions, so the scenes will change. His hard work paid off when she accepted his offer for subsequent dates until finally agreeing to become officially girlfriend after two meetups full of stimulating conversation topics.</span>
                    </div>
                </MaxWidth>
            </div>
            <div className={styles.gameElements} id="play" ref={refs.playRef}>
                <MaxWidth>
                    <div className={styles.gameIdeaInfo}>
                    </div>
                        <div className={styles.gameplaySlideData}>
                            <Swiper
                                init={true}
                                slidesPerView={"auto"}
                                onActiveIndexChange={e => {
                                    setBrokeSlide(prev => ({
                                        ...prev,
                                        isBeginning: e.isBeginning,
                                        isEnd: e.isEnd
                                    }))
                                }}
                                navigation={{
                                    prevEl: '.swiper-prev-gameplay',
                                    nextEl: '.swiper-next-gameplay'
                                }}
                                spaceBetween={25}
                                slidesPerGroup={2}
                                className={"swiper_girls"}
                                wrapperClass="wrapper-2"
                                breakpoints={{
                                    1002: {
                                        slidesPerView:2,
                                        slidesPerGroup: 2,
                                    },
                                    1001:{
                                        slidesPerView: 1,
                                        slidesPerGroup:1
                                    },
                                    768: {
                                        slidesPerView: "auto",
                                        slidesPerGroup:1
                                    },
                                    320:{
                                        slidesPerView: 1,
                                        slidesPerGroup:1
                                    }
                                }}
                            >
                                {slidesGirls.map((el, i) => (
                                    <SwiperSlide key={i} className="swiper-slide-girls">
                                        <div className={styles.childSlide2}>
                                            <img src={el} alt="" />
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                            <div className='swiper-prev-gameplay' style={{ opacity: `${brokeSlide.isBeginning ? "0.3" : "0.7"}` }}></div>
                            <div className='swiper-next-gameplay' style={{ opacity: `${brokeSlide.isEnd ? "0.3" : "0.7"}` }}></div>
                        </div>
                </MaxWidth>
            </div>
            <div className={styles.aboutUs} id="about_us" ref={refs.aboutRef}>
                <MaxWidth>
                    <div className={styles.gameIdeaTop}>
                        <div className={styles.gameIdeaBgDiv}></div>
                        <span className={styles.gameIdeaText}>About Us</span>
                    </div>
                    <div className={styles.gameIdeaInfo}>
                        <span className={styles.gameIdeaInfoText}>HAYQ Studios is a Los Angeles-based game agency specializing in the development of original, innovative, and captivating gaming experiences. With a team of highly experienced designers, developers, and programmers, HAYQ Studios is committed to delivering cutting-edge innovation and quality with every product. Our mission is to create games that spark imagination, ignite passion, and foster connection between players.</span>
                    </div>
                </MaxWidth>
            </div>
            <div className={styles.partners}>
                <MaxWidth>
                    <div className={styles.ourPartners}>
                        <div className={styles.gameIdeaTop}>
                            <div className={styles.gameIdeaBgDiv}></div>
                            <span className={styles.gameIdeaText}>Our Partners</span>
                        </div>
                    </div>

                    <div className={styles.partnersFlex}>
                        <Marquee play={true} gradientWidth={0} speed={40}>
                            {slides3.map((el, i) => (
                                <div className={styles.childSlideElement} key={i}>
                                    <img src={el} alt="" />
                                </div>
                            ))}
                        </Marquee>
                    </div>
                </MaxWidth>
            </div>
        </div>
    )
}


export default Main