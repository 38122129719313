import MaxWidth from '../MaxWidth/MaxWidth'
import logo from '../../assets/images/logo.svg'
import soc1 from '../../assets/images/soc1.svg'
import soc2 from '../../assets/images/soc2.svg'
import soc3 from '../../assets/images/soc3.svg'
import soc4 from '../../assets/images/soc4.svg'
import map from '../../assets/images/map.png'
import styles from './Footer.module.css'

const Footer = ({contactRef}) => {
    const socNets = [
        {
            image: soc1,
            link: "/"
        },
        {
            image: soc2,
            link: "/"
        },
        {
            image: soc3,
            link: "/"
        },
        {
            image: soc4,
            link: "/"
        },
    ]
    return (
        <div className={styles.footer} id="contact_us" ref={contactRef}>
            <MaxWidth>
                <div className={styles.footerFlex}>
                    <div className={styles.footerLeft}>
                        <div className={styles.footerLogo}>
                            <img src={logo} alt="" />
                            <span>HAYQ Studios</span>
                        </div>
                        <div className={styles.hayqGamesInfoDiv}>
                            <span className={styles.hayqGamesInformation}>HAYQ Studios is a Los Angeles-based game agency specializing in the development of original, innovative, and captivating gaming experiences.</span>
                            <a href='mailto:contact@hayqgames.com' className={`${styles.hayqGamesInformation} ${styles.email}`}>contact@hayqgames.com</a>
                        </div>
                        <div className={styles.socFlex}>
                            {socNets.map((el, i) => (
                                <a key={i} href={el.link} className={styles.soc}>
                                    <img src={el.image} alt="" />
                                </a>
                            ))}
                        </div>
                    </div>
                    <div className={styles.footerCenter}>
                        <div className={styles.footerCenterFlex}>
                            <span className={styles.footerMainText}>
                                General
                            </span>
                        </div>
                        <span className={styles.footerNotMainText}>Our Games</span>
                        <span className={styles.footerNotMainText}>News</span>
                        <span className={styles.footerNotMainText}>Our Games</span>
                    </div>
                    <div className={styles.footerRight}>
                        <span className={styles.footerMainText}>
                            Maps Location
                        </span>
                        <div className={styles.footerMap}>
                            <a href='https://hayqgames.com/' target={"_blank"} className={styles.mapLink}>
                                <img src={map} alt="" />
                            </a>
                        </div>
                    </div>
                </div>
            </MaxWidth>
        </div>
    )
}

export default Footer